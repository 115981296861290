import React from "react";
import Views from "./views";
import Analytics from "./Analytics";
import './assets/styles/app.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import Tracker from "./components/Tracker";

function App() {
  return (
  <>
   <Views/>
  <Tracker/>

  </>
   
    
  );
}

export default App;
